@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #F2F2F4;
}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type='radio'] {
  position: relative;
}
input[type='radio']:before {
  width: 1rem;
  height: 1rem;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid rgba(0,0,0,0.66);
}

input[type='radio']:checked:after {
  width: 8px;
  height: 8px;
  border-radius: 15px;
  top: 3px;
  left: 3px;
  position: absolute;
  background-color: #7030A0;
  content: '';
  display: inline-block;
  visibility: visible;
}
